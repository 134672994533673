import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Footer from './components/Footer';
import Homepage from './pages/Homepage';
import PageNotFound from './pages/PageNotFound';
import { ApolloProvider } from '@apollo/client';
import { client } from './utils/client';
import './styles/index.scss';
import { UIThemeProvider } from '@kontentino/ui';

function App() {
  return (
    <ApolloProvider client={client}>
      <UIThemeProvider>
        <Router>
          <Switch>
            <Route path="/" component={Homepage} exact/>
            <Route path="/" component={PageNotFound}/>
          </Switch>

          <Footer/>
        </Router>
      </UIThemeProvider>
    </ApolloProvider>
  );
}

export default App;
