import React, { useState } from 'react';
import {NavDropdown, Nav, Navbar} from 'react-bootstrap';
import classes from 'react-style-classes'
import style from './Navigation.module.css';
import logo from '../images/logo.png';

const Navigation = () => {
  const [offset, setOffset] = useState(0);

  document.addEventListener('scroll', () => {
    setOffset(window.pageYOffset);
  })
  return (
  <div className={classes(style.navBar, offset !== 0 && style.fixed)}>
      <Navbar className={style.wrapper} expand="lg">
      <div className={style.logo}>
        <a href="https://kontentino.com">
          <img
            alt="Kontentino"
            src={logo}
            style={{ width: '150px', marginRight: 5 }}
            className="d-inline-block align-top"
          />
        </a>
      </div>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className={style.toggler}>
        <span className={style.togglerLine} />
        <span className={style.togglerLine} />
        <span className={style.togglerLine} />
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav" className={style.collapseWrapper}>
        <Nav className={style.nav}>
          <Nav.Link className={style.link} href="https://www.kontentino.com/why-kontentino" target='_blank'>WHY KONTENTINO</Nav.Link>
          <NavDropdown 
            className={style.linkParent} 
            title={(
              <span className={style.link}>
                SOLUTIONS
              </span>
            )} 
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/mobile-app" target='_blank'>
              Mobile app
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/planning-and-collaboration" target='_blank'>
              Planning and collaboration
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/client-approvals" target='_blank'>
              Client approvals
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/fb-ads-manager" target='_blank'>
              Managing Facebook ads
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/ig-creator-studio" target='_blank'>
              IG Creator Studio
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/insights-and-reporting" target='_blank'>
              Insights and reporting
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/enterprise" target='_blank'>
              Enterprise
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link className={style.link} href="https://www.kontentino.com/pricing" target='_balnk'>PRICING</Nav.Link>
          <NavDropdown
            className={style.linkParent}
            title={(
              <span className={style.link}>
                RESOURCES
              </span>
            )}
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/tutorials" target='_balnk'>
              Tutorials
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/blog/" target='_balnk'>
              Blog
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/ebooks" target='_balnk'>
              Ebooks
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/roadmap" target='_balnk'>
              Product road map
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link className={style.link} href="https://www.kontentino.com/faqs" target='_blank'>FAQs</Nav.Link>
          <Nav.Link className={style.link} href="https://www.kontentino.com/login" target='_blank'>LOGIN</Nav.Link>
          <a 
            href="mailto:mailto:sales@kontentino.com?subject=Tell me more about Kontentino&body=Hello%0DI would like to know more information about Kontentino, please contact me on this email.%0DBest,%0D"
            className={classes(style.button, style.varA)}
          >
            CONTACT SALES
          </a>
          <a href='https://www.kontentino.com/signup' target='_balnk' rel='noreferrer' className={classes(style.button, style.varB)}>
            TRY FOR FREE
          </a>
        </Nav>
        </Navbar.Collapse>
      </Navbar>
  </div>
)};

export default React.memo(Navigation);
