import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import logo from '../images/logo.png';
import Col from 'react-bootstrap/Col';
import style from './Footer.module.css';
import classes from 'react-style-classes';
import spotifyPodcast from '../images/spotify-podcast-icon.png';
import podbeanPodcast from '../images/podbean-podcast-icon.png';
import badge1 from '../images/badges/capterra.png';
import badge2 from '../images/badges/capterra.svg';
import badge3 from '../images/badges/getapp.png';
import badge4 from '../images/badges/g2crowd.svg';
import appStoreIcon from '../images/app-store-icon.png';
import googlePlayIcon from '../images/google-play-icon.png';
import { Icon } from "@kontentino/ui";
import { faFacebook, faInstagram, faLinkedin, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";

const Footer = () => (
  <footer className="footer mt-auto">
    <div className={style.footer}>
      <Container>
        <Row>
          <Col md='3'>
              <img src={logo} alt='logo' className={style.logoSmall}/>
              <div className={style.socialMediaWrapper}>
                <a href='https://www.facebook.com/kontentino' target='_blank' rel='noreferrer'>
                  <Icon icon={faFacebook} size='sm' />
                </a>
                <a href='https://www.instagram.com/kontentino/' target='_blank' rel='noreferrer'>
                  <Icon icon={faInstagram} size='sm' />
                </a>
                <a href='https://www.linkedin.com/company/kontentino/' target='_blank' rel='noreferrer'>
                  <Icon icon={faLinkedin} size='sm' />
                </a>
                <a href='https://twitter.com/kontentino/' target='_blank' rel='noreferrer'>
                  <Icon icon={faTwitter} size='sm' />
                </a>
                <a href='https://www.youtube.com/c/kontentino' target='_blank' rel='noreferrer'>
                  <Icon icon={faYoutube} size='sm' />
                </a>
              </div>
          </Col>
          <Col md='3'>
              <strong className={style.sectionTitle}>CHECK ALSO</strong>
              <ul className={style.linksList}>
                <li>
                  <a href='https://www.kontentino.com/kontentino-vs-hootsuite' target='_blank' rel='noreferrer'>
                    Kontentino vs Hootsuite
                  </a>
                </li>
                <li>
                  <a href='https://www.kontentino.com/kontentino-vs-buffer' target='_blank' rel='noreferrer'>
                    Kontentino vs Buffer
                  </a>
                </li>
                <li>
                  <a href='https://www.kontentino.com/blog/sprout-social-vs-kontentino-which-tool-is-better-for-you/' target='_blank' rel='noreferrer'>
                    Kontentino vs Sprout Social
                  </a>
                </li>
                <li>
                  <a href='https://www.kontentino.com/blog/sendible-vs-kontentino-which-one-is-better-for-you/' target='_blank' rel='noreferrer'>
                    Kontentino vs Sendible
                  </a>
                </li>
                <li>
                  <a href='https://www.kontentino.com/blog/coschedule-vs-kontentino-which-tool-is-better-for-you/' target='_blank' rel='noreferrer'>
                    Kontentino vs CoSchedule
                  </a>
                </li>
                <li>
                  <a href='https://www.kontentino.com/for-remote-marketing-teams' target='_blank' rel='noreferrer'>
                    Kontentino vs remote teams
                  </a>
                </li>
                <li>
                  <a href='https://www.kontentino.com/facebook-planning-management-tool' target='_blank' rel='noreferrer'>
                    Kontentino vs FB management
                  </a>
                </li>
                <li>
                  <a href='https://www.kontentino.com/linkedin-planning-management-tool' target='_blank' rel='noreferrer'>
                    Kontentino vs LinkedIn management
                  </a>
                </li>
              </ul>
          </Col>
          <Col md='3'>
              <div>
                <strong className={style.sectionTitle}>PODCAST</strong>
                <a href='https://open.spotify.com/show/3tIzuDL4MgxMq9cXzJmyUP' target='_blank' rel='noreferrer'>
                  <img className={style.podcastIcon} src={spotifyPodcast} alt='spotify podcast' />
                </a>
                <a href='https://kontentino.podbean.com/' target='_blank' rel='noreferrer'>
                  <img className={style.podcastIcon} src={podbeanPodcast} alt='podbean podcast' />
                </a>
                <strong className={style.sectionTitle}>PEOPLE'S VOICE</strong>
                <div className={style.badgesWrtapper}>
                  <a href='https://www.capterra.com/social-media-marketing-software/?utf8=%E2%9C%93&v=1#infographic' target='_blank' rel='noreferrer'>
                    <img src={badge1} alt='capterra' className={style.badge}/>
                  </a>
                  <a href='https://www.capterra.com/p/152937/Kontentino/' target='_blank' rel='noreferrer'>
                    <img src={badge2} alt='capterra' className={style.badge}/>
                  </a>
                  <a href='https://www.getapp.com/marketing-software/a/kontentino/' target='_blank' rel='noreferrer'>
                    <img src={badge3} alt='getapp' className={style.badge}/>
                  </a>
                  <a href='https://www.g2.com/products/kontentino/reviews' target='_blank' rel='noreferrer'>
                    <img src={badge4} alt='g2crowd' className={style.badge}/>
                  </a>
                </div>
              </div>
          </Col>
          <Col md='3'>
              <strong className={style.sectionTitle}>NEWSLETTER</strong>
              <span>To subscribe for newsletter, please visit our <a target='_blank' href='https://www.kontentino.com/login' rel='noreferrer'>main website</a>.</span>
          </Col>
        </Row>
      </Container>
    </div>
    <div className={style.subFooter}>
      <Container>
        <Row className="justify-content-md-center">
          <Col lg='auto'>
            <span className={style.linksTitle}>
              <strong>important links</strong>
            </span>
          </Col>
          <Col lg='auto' md={3} sm={4}>
            <a
              href='https://www.kontentino.com/faqs'
              target='_blank'
              rel='noreferrer'
              className={style.footerLink}
            >
              faq
            </a>
          </Col>
          <Col lg='auto' md={3} sm={4}>
            <a
              href='https://www.kontentino.com/pricing'
              target='_blank'
              rel='noreferrer'
              className={style.footerLink}
            >
              pricing
            </a>
          </Col>
          <Col lg='auto' md={3} sm={4}>
            <a
              href='https://www.kontentino.com/blog/'
              target='_blank'
              rel='noreferrer'
              className={style.footerLink}
            >
              blog
            </a>
          </Col>
          <Col lg='auto' md={3} sm={4}>
            <a
              href='https://www.kontentino.com/tutorials'
              target='_blank'
              rel='noreferrer'
              className={style.footerLink}
            >
              tutorials
            </a>
          </Col>
          <Col lg='auto' md={3} sm={4}>
            <a
              href='https://www.welcometothejungle.com/sk/companies/kontentino'
              target='_blank'
              rel='noreferrer'
              className={style.footerLink}
            >
              careers
            </a>
          </Col>
          <Col lg='auto' md={3} sm={4}>
            <a
              href='https://www.kontentino.com/terms-of-service'
              target='_blank'
              rel='noreferrer'
              className={style.footerLink}
            >
              terms of service
            </a>
          </Col>
          <Col lg='auto' md={3} sm={4}>
            <a
              href='https://www.kontentino.com/privacy-policy'
              target='_blank'
              rel='noreferrer'
              className={style.footerLink}
            >
              privacy policy
            </a>
          </Col>
          <Col lg='auto' md={3} sm={4}>
            <a
              href='https://www.kontentino.com/data-policy'
              target='_blank'
              rel='noreferrer'
              className={classes(style.footerLink, style.last)}
            >
              data policy
            </a>
          </Col>
          <Col lg='auto' md={12}>
            <a href='https://apps.apple.com/us/app/kontentino-comment-approve/id1170516252' target='_blank' rel='noreferrer'>
              <img src={appStoreIcon} alt='appStore' className={style.storeIcon} />
            </a>
          </Col>
          <Col lg='auto' md={12}>
            <a href='https://play.google.com/store/apps/details?id=com.kontentino2&hl=en' target='_blank' rel='noreferrer'>
              <img src={googlePlayIcon} alt='googlePlay' className={style.storeIcon} />
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  </footer>
);

export default React.memo(Footer);
