import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Body from '../components/Body';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Navigation from '../components/Navigation';
import { Button, Alert, toast } from '@kontentino/ui';
import { gql, useMutation } from '@apollo/client';
import { isValidHttpUrl } from "../utils/url";


const ADD_LINK = gql`
  mutation createLinkMutation(
  $input: NewLink!
) {
  createLink(
    input: $input
  ) {
    id
    shortUrl
  }
}
`;

const Home: React.FC = () => {
  const [url, setUrl] = useState('');
  const [validUrl, setValidUrl] = useState('');
  const [preview, setPreview] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [addNewLinkMutation, {loading}] = useMutation(ADD_LINK);

  const onUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setUrl(value);

    try {
      const urlToValidate = value.toLowerCase().startsWith('http') ? value : `https://${value}`;

      isValidHttpUrl(urlToValidate);

      setValidUrl(urlToValidate);
      setHasError(false);
    } catch (error) {
      setValidUrl('');
      setHasError(true);
    }
  };

  const addLink = async () => {
    await addNewLinkMutation({
      variables: {
        input: {
          name: 'Link',
          url: validUrl,
        },
      },
    }).then(response => setPreview(response.data.createLink.shortUrl))
      .catch(error => {
        let message = 'Something went wrong. Please try again later.'

        if (Array.isArray(error?.graphQLErrors) && error?.graphQLErrors?.length > 0) {
          message = error.graphQLErrors.map((error: { message: string }) => error.message).join(' ')
        }

        toast(message, {type: 'error'})
      });
  };

  const handleAddNewLink = (e: any) => {
    e.preventDefault();
    if (url.length !== 0) {
      addLink();
    } else {
      toast('Please insert URL Address', {type: 'warning'})
    }
  }

  const onCopyUrlClick = () => {
    const el = document.createElement('textarea');
    el.value = preview ?? '';
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    toast('URL copied to clipboard', {type: 'success'})
  }

  const handleClear = () => {
    setUrl('');
    setPreview(null)
  }

  return (
    <>
      <Navigation/>
      <Body>
        <Container>
          <Row>
            <Col>
              <h2>URL Shortener</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              {
                hasError ? (
                  <Alert variant="warning">URL address is not valid!</Alert>
                ) : null
              }
              <Form>
                <Form.Group controlId="newURLAddress">
                  <Form.Label className='tw-font-medium tw-text-md'>URL Address</Form.Label>
                  <Form.Control required type="text" placeholder="https://" value={url} onChange={onUrlChange}/>
                  <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>
                  <Form.Text className="text-muted" style={{wordBreak: 'break-all'}}>{validUrl}</Form.Text>
                  {!preview && (
                    <Button
                      type='submit'
                      onClick={(e: any) => handleAddNewLink(e)}
                      className='tw-mt-4 tw-ml-auto tw-flex'
                      disabled={hasError}
                      isLoading={loading}
                    >
                      Shorten
                    </Button>
                  )}
                </Form.Group>
              </Form>
            </Col>
          </Row>
          {preview && (
            <Row>
              <Col>
                <span className='tw-font-medium tw-text-md'>Short URL</span>
                <Alert variant='info'>
                  {preview}
                </Alert>
                <div className='tw-flex tw-gap-2 tw-justify-end tw-mt-4'>
                  <Button onClick={onCopyUrlClick}>
                    Copy
                  </Button>
                  <Button
                    variant='secondary'
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </Body>
    </>
  );
};

export default Home;
