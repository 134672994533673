import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const PageNotFound: React.FC = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h1 className="text-center">Page not found!</h1>
        </Col>
      </Row>
    </Container>
  );
}

export default PageNotFound;
