import {ApolloClient, InMemoryCache} from '@apollo/client';
import {setContext} from 'apollo-link-context';
import {ApolloLink} from 'apollo-link';
import {createHttpLink} from 'apollo-link-http';

export const FetchPolicies = {
    NetworkOnly: 'network-only',
    NoCache: 'no-cache',
};

const defaultOptions = {
    watchQuery: {
        fetchPolicy: FetchPolicies.NetworkOnly,
    },
    query: {
        fetchPolicy: FetchPolicies.NetworkOnly,
    },
    mutation: {
        fetchPolicy: FetchPolicies.NetworkOnly,
    },
};

const authLink = setContext((_, {headers}) => {
    return {
        headers: {
            ...headers,
        },
    };
});

export const client = new ApolloClient({
    link: ApolloLink.from([
        authLink,
        createHttpLink({
            uri: `${process.env.REACT_APP_LINK_SHORTENER_ENDPOINT}/graphql`,
        }),
    ]),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
});
