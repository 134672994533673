import isURL from 'validator/lib/isURL'

export function isValidHttpUrl(url: string) {
  const isValid = isURL(url);

  if (!isValid) {
    throw new Error('Invalid URL');
  }

  return isValid;
}
